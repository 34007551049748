import React, { useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { X } from 'react-feather';
import { calculateAverageSentiment } from '../../../../utils/calculateAverageSentiment';
import { Cluster, ExtractCluster } from '../types';
import { Pagination } from '../../../../components/Molecules';
import { ListViewRow } from './ListViewRow';
import { EmptyState } from '../../../../components/EmptyState';
import { getRoom } from '../../../../utils/variables';
import { useUser } from '../../../../context/user/UserContext';
import { SelectAllCheckBox } from '../../../../components/Form/CheckBox';
import { ClustersTableActionsBar } from './ClustersTableActionsBar';
import { RootState } from '../../../../store';
import { ClusterWrapper } from '..';
import { TabsContainer } from '../SelectedClusterView/TabsContainer';
import { ActionButtons } from '../SelectedClusterView/ActionButtons';
import { resetSelectedCluster } from '../../store';
import { Loading } from '../../../../components/Loading';
import { TooltipWrapper } from '../../../../components/ToolTip/TooltipWrapper';
import { useRemoveRestoreCluster } from '../../../../services/Clusters/removeRestore';
import { NoSearchResults } from '../../../../assets/svg/noSearchResults';

export type SelectedClusters = {
    id: string
    isWatching: boolean
    title?: string
    status?: string
    subClustersMappedId?: string[]
}

type NarrativeThemeTableProps = {
    clusters: Cluster[]
    total: number
    offset: number
    onSetSelectedCluster: (id: string) => void
    onOffsetChange: (e: number) => void
    itemsPerPage: number
    isCompareModal?: boolean
    isFromWatchlist?: boolean
    isFromRemoveContent?: boolean
}

export const ClustersTable = ({
    clusters,
    total,
    offset,
    onSetSelectedCluster,
    onOffsetChange,
    itemsPerPage,
    isCompareModal = false,
    isFromWatchlist = false,
    isFromRemoveContent = false
}: NarrativeThemeTableProps) => {
    const room = getRoom();
    const { notAllowed } = useUser();
    const dispatch = useDispatch();

    const [selectedClusters, setSelectedClusters] = useState([] as SelectedClusters[]);
    const contentActionsEnabled = room.instance?.plan?.others?.contentActions;
    const noActionsAllowed = notAllowed.includes('editData') || !contentActionsEnabled;
    const formattedData = clusters?.map((cluster) => (
        { ...cluster, averageSentiment: calculateAverageSentiment(cluster.sentimentJson) }
    ));
    const handleClearSelection = () => setSelectedClusters([]);
    const handleSetSelectedClusters = (elements: SelectedClusters[]) => setSelectedClusters(elements);
    const { selectedCluster: selectedNarrativeTheme, isParentCluster } = useSelector((state: RootState) => state.selectedCluster);
    const { handlePermanantRemoveClusters, handleRestoreClusters,
        handleSendtoRemoveContent, loading: restoreOrRemoveLoading } = useRemoveRestoreCluster();
    const handleRecover = (id: string, subClustersMappedId: string[]) => {
        handleRestoreClusters([id], 'cluster', subClustersMappedId);
    };
    const handleRemove = (id: string, status: string, subClustersMappedId: string[]) => {
        if (status === 'deleted') {
            handlePermanantRemoveClusters([id], subClustersMappedId);
        } else {
            handleSendtoRemoveContent([id], 'cluster', subClustersMappedId);
        }
    };
    useEffect(() => {
        const newSelectedClusters = [];
        for (const selectedCluster of selectedClusters) {
            const cluster = clusters.find(({ id }) => id === selectedCluster.id);
            if (cluster) {
                newSelectedClusters.push({
                    id: cluster.id,
                    isWatching: cluster.isWatching,
                    title: cluster?.title || '',
                    status: cluster?.status
                });
            }
        }
        if (JSON.stringify(newSelectedClusters) !== JSON.stringify(selectedClusters)) {
            setSelectedClusters(newSelectedClusters);
        }
    }, [clusters, selectedClusters]);
    const isNarrativeTheme = Boolean(selectedNarrativeTheme && isParentCluster);
    if (clusters?.length < 1 && !isFromWatchlist) {
        return (
            <div className="border">
                <EmptyState title="No data found."
                    summary="Please change the search string and try again."
                    svgIcon={<NoSearchResults />}
                />
            </div>
        );
    }
    return (
        <div className={`d-flex align-items-stretch ${isNarrativeTheme ? 'border' : ''}`}>
            <div style={{ minWidth: 410 }}
                className={isNarrativeTheme
                    ? 'selected-cluster-list-view-height scrollbar-small overflow-y-auto maxw-450 bg-white border-right'
                    : 'flex-1'}
            >
                <div className="bg-white overflow-x-auto">
                    <Table className={`m-0 border${isNarrativeTheme ? '-0' : ''}`} hover>
                        <thead>
                            <tr>
                                {(!noActionsAllowed && !isNarrativeTheme && !isCompareModal) && (
                                    <td className="pr-0 pl-2 select-all" data-testid="select-all-content">
                                        <SelectAllCheckBox id="subClusters"
                                            displayLabel={false}
                                            options={formattedData.map(
                                                cluster => ({ id: cluster.id, isWatching: cluster.isWatching })
                                            )}
                                            values={selectedClusters}
                                            allCheck={options => setSelectedClusters(options as SelectedClusters[])}
                                            page="content-table"
                                        />
                                    </td>
                                )}
                                <td className={`px-2 table-title ${isNarrativeTheme ? 'border-top-0' : ''}`}>Narrative themes</td>
                                {isFromRemoveContent && selectedClusters.length === 0 && (
                                    <>
                                        <td className="px-3 table-title">Removed by</td>
                                        <td className="px-3 text-right table-title">Actions</td>
                                    </>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {formattedData.map((cluster: Cluster) => (
                                <ListViewRow key={cluster.id}
                                    setSelectedCluster={onSetSelectedCluster}
                                    cluster={cluster}
                                    selectedClusters={selectedClusters}
                                    onSetSelectedClusters={handleSetSelectedClusters}
                                    noActionsAllowed={noActionsAllowed}
                                    compact={isNarrativeTheme}
                                    isCompareModal={isCompareModal}
                                    isFromRemoveContent={isFromRemoveContent}
                                    handleRecover={handleRecover}
                                    handleRemove={handleRemove}
                                    restoreOrRemoveLoading={restoreOrRemoveLoading}
                                />
                            ))}
                        </tbody>
                    </Table>
                </div>
                {selectedClusters.length ? (
                    <ClustersTableActionsBar selectedClusters={selectedClusters}
                        clearSelection={handleClearSelection}
                        isFromRemoveContent={isFromRemoveContent}
                        isParent
                    />
                ) : (
                    <Pagination className="p-2 border mb-0"
                        total={total}
                        itemsPerPage={itemsPerPage}
                        offset={offset}
                        setOffset={onOffsetChange}
                    />
                )}
            </div>
            {isNarrativeTheme && (
                <div className="flex-1 bg-white">
                    <ClusterWrapper id={selectedNarrativeTheme}
                        renderComponent={(cluster, loading, subClustersLoading) => {
                            if (loading) return <Loading relative height={500} />;
                            return (
                                <div id="selected-cluster-view" className="bg-white h-100 d-flex flex-column">
                                    <div className="d-flex align-items-center p-3">
                                        <p className="font-weight-bold m-0 w-75 mr-auto">{cluster.title}</p>
                                        <ActionButtons clusterTitle={cluster.title}
                                            summary={cluster.description}
                                            isWatching={cluster?.isWatching}
                                            cluster={cluster}
                                            isParent
                                            isCompareModal={isCompareModal}
                                            subClustersMappedId={cluster?.subClustersMappedId}
                                        />
                                        <TooltipWrapper tooltipText="Close"
                                            id="close_details_tab"
                                            placement="top-start"
                                            container="selected-cluster-view"
                                        >
                                            <Button color="link"
                                                className="p-0 mw-0 ml-2"
                                                onClick={() => dispatch(resetSelectedCluster())}
                                            >
                                                <X size={26} />
                                            </Button>
                                        </TooltipWrapper>
                                    </div>
                                    <hr className="m-0" />
                                    <TabsContainer cluster={cluster as ExtractCluster<typeof cluster>}
                                        isParent
                                        subClustersLoading={subClustersLoading}
                                    />
                                </div>
                            );
                        }}
                        disableLoading
                    />
                </div>
            )}
        </div>
    );
};
