import React, { useRef, useState } from 'react';
import { DropdownItem } from 'reactstrap';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Options } from 'highcharts';
import { useTotalThreatsAndMatches } from '../../../../../services/Overview';
import { Loading } from '../../../../../components/Loading';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { TrendCard } from './TrendCard';
import { LineChart, LineChartRef } from './LineChart';
import { ActionsDropdown } from '../../../../../components/Atoms/ActionsDropdown';
import { HandleDateRangeState } from './HandleDateRange';
import { checkAndGetUnix } from '../../../../../utils/getTimeFrom';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';
import { RootState } from '../../../../../store';
import { DateButtons } from './DateButtons';
import { getRoom } from '../../../../../utils/variables';

type TotalMatchesAndThreatsOverTimeProps = {
    preview?: boolean
}

export const TotalMatchesAndThreatsOverTime = ({ preview }: TotalMatchesAndThreatsOverTimeProps) => {
    const room = getRoom();
    const [dateRange, setDateRange] = useState<{ startDate: number, endDate: number } | null>(null);
    const [selectedDateRangeLabel, setSelectedDateRangeLabel] = useState('All time');

    const globalFilters = useSelector((state: RootState) => state.filters.filters);

    const isFiltersAvailable = globalFilters?.dateRange?.startDate !== moment(moment(room.start_date).toDate()).unix()
    && globalFilters?.dateRange?.startDate !== 0;

    const { data: totalMatchesAndThreatsOverTime, loading } = useTotalThreatsAndMatches({
        maxItems: (7 * 24 + 10).toString(),
        ...((dateRange) ? { dateFilter: dateRange } : {})
    });
    const [showMatches, setShowMatches] = useState(true);
    const [showThreats, setShowThreats] = useState(true);
    const dateRef = useRef(null);

    const dataExists = totalMatchesAndThreatsOverTime?.matches?.data?.length
        && totalMatchesAndThreatsOverTime.matches?.data?.length > 1;

    const chartRef = useRef<LineChartRef>(null);

    const toggleRange = (start: number, end: number, selectedLabel: string) => {
        setDateRange({
            startDate: checkAndGetUnix(start),
            endDate: checkAndGetUnix(end)
        });
        setSelectedDateRangeLabel(selectedLabel);
    };

    const chartExportOptions: Options = {
        legend: {
            enabled: true,
            symbolWidth: 10
        },
        chart: {
            height: 500
        }
    };

    const layout = (children: React.ReactNode) => (
        <div data-testid="multi-small-charts" className="highcharts-stock-chart">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <h3>Matches and threats over time</h3>
                <div className="d-flex align-items-center">
                    {isFiltersAvailable ? null : (
                        <>
                            <DateButtons toggleRange={toggleRange}
                                selectedDateRangeLabel={selectedDateRangeLabel}
                                setDateRange={setDateRange}
                                setSelectedDateRangeLabel={setSelectedDateRangeLabel}
                            />
                            <HandleDateRangeState ref={dateRef}
                                toggleRange={(a, b) => toggleRange(a, b, 'custom')}
                                selected={selectedDateRangeLabel === 'custom'}
                            />
                        </>
                    )}
                    <ActionsDropdown className="ml-1">
                        <DropdownItem onClick={() => chartRef.current?.exportChart({ chartOptions: chartExportOptions })}>
                            Export as JPEG
                        </DropdownItem>
                    </ActionsDropdown>
                </div>
            </div>
            <hr className="mx-n3 mb-3" />
            {children}
        </div>
    );
    if (loading) {
        return layout(<Loading relative height={500} />);
    }
    if (!dataExists) {
        return layout(<EmptyState title="No data available" icon={Images().overview} />);
    }
    const { matches, threats, interval } = totalMatchesAndThreatsOverTime;
    const startDate = new Date(matches.data[0].key);
    const endDate = new Date(matches.data[matches.data.length - 1].key);

    const tooltipText = `The date filter applies only to this timeline graph and the displayed numbers. 
    It does not affect other widgets on the page.`;

    return layout(
        <div>
            <div className="mb-2 d-flex">
                {isFiltersAvailable ? <p className="m-0">Displaying</p> : (
                    <TooltipWrapper tooltipText={tooltipText} id="matches-threats-daterange-tooltipe" placement="top-start">
                        <p className="mb-0 widget-text-style">Displaying</p>
                    </TooltipWrapper>
                )}
                <p className="mb-0 ml-11">
                    timeline data from {moment(startDate).format('DD/MM/YYYY')} to {moment(endDate).format('DD/MM/YYYY')}
                </p>
            </div>
            <div className="d-flex">
                <TrendCard id="showMatches"
                    label="Matches"
                    data={matches.data}
                    show={showMatches}
                    setShow={setShowMatches}
                    totalCount={matches.count}
                    tooltip="Matches are the content that is related to the keywords in your situation room."
                    interval={interval}
                />
                <TrendCard id="showThreats"
                    label="Threats"
                    data={threats.data}
                    show={showThreats}
                    setShow={setShowThreats}
                    totalCount={threats.count}
                    tooltip={`Threats are content that may harm individuals, communities, or the 
                        overall online environment, particularly on social media platforms.`}
                    interval={interval}
                />
            </div>
            <hr className="mx-n3 my-3" />
            <p className="mb-3">To zoom in on a specific period or date, you can click and drag horizontally within the graph</p>
            <LineChart ref={chartRef}
                preview={preview}
                interval={interval}
                data={[
                    {
                        name: 'Matches',
                        data: matches.data.map((item) => ({ x: item.key, y: item.count })),
                        color: '#00C0C7',
                        visible: showMatches
                    },
                    {
                        name: 'Threats',
                        data: threats.data.map((item) => ({ x: item.key, y: item.count })),
                        color: '#D945D0',
                        visible: showThreats
                    }
                ]}
            />
        </div>
    );
};
