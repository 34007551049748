import { gql } from '@apollo/client';
/* eslint-disable */
export const CREATE_USER = gql`
    mutation createUser($uid: String!, $email: String!, $displayName: String, $firstName: String, $lastName: String, $photoURL: String, $role: String, $instance: ID, $status: String, $lastSeen: DateTime, $bio: String, $jobDescription: String, $preferences: PreferencesInput, $truncateName: Int) {
        createUser(data: {
             uid: $uid,
             email: $email,
             displayName: $displayName,
             firstName: $firstName,
             lastName: $lastName,
             photoURL: $photoURL,
             role: $role,
             instance: $instance,
             status: $status,
             lastSeen: $lastSeen,
             bio: $bio,
             jobDescription: $jobDescription
             preferences: $preferences
            }) {
            id
            uid
            email
            displayName(truncate: $truncateName)
            firstName
            lastName
            photoURL
            role
            updatedAt
            instance {
                id
                account {
                    accountManager
                }
                plan {
                    core {
                        overview
                        threats
                        logicallyFactChecks
                        narratives
                    }
                    integrations {
                        investigations
                    }
                }
            }
            bio
            situationRoomViewList{
                situationRoomid
                lastViewed
                project_id
            }
            jobDescription
            preferences {
                cookies
                marketingEmails
                productEmails
                darkmode
            }
            welcomeMessage
        }
    }
`;

export const CREATE_SSO_USER = gql`
    mutation createSSOUser($uid: String!, $email: String!, $displayName: String, $firstName: String, $lastName: String, $photoURL: String, $role: String, $instance: ID, $status: String, $lastSeen: DateTime, $bio: String, $jobDescription: String, $preferences: PreferencesInput, $truncateName: Int) {
        createSSOUser(data: {
             uid: $uid,
             email: $email,
             displayName: $displayName,
             firstName: $firstName,
             lastName: $lastName,
             photoURL: $photoURL,
             role: $role,
             instance: $instance,
             status: $status,
             lastSeen: $lastSeen,
             bio: $bio,
             jobDescription: $jobDescription
             preferences: $preferences
            }) {
            id
            uid
            email
            displayName(truncate: $truncateName)
            firstName
            lastName
            photoURL
            role
            updatedAt
            instance {
                id
                account {
                    accountManager
                }
                plan {
                    core {
                        overview
                        threats
                        logicallyFactChecks
                        narratives
                    }
                    integrations {
                        investigations
                    }
                }
            }
            bio
            situationRoomViewList{
                situationRoomid
                lastViewed
                project_id
            }
            jobDescription
            preferences {
                cookies
                marketingEmails
                productEmails
                darkmode
            }
            welcomeMessage
        }
    }
`;

export const UPDATE_USER = gql`
    mutation updateUser($data: UserInput, $truncateName: Int) {
        updateUser(data: $data) {
            id
            uid
            email
            displayName(truncate: $truncateName)
            firstName
            lastName
            photoURL
            role
            updatedAt
            instance {
                id
                account {
                    accountManager
                }
                plan {
                    core {
                        overview
                        threats
                        logicallyFactChecks
                        narratives
                    }
                    integrations {
                        investigations
                    }
                }
            }
            bio
            situationRoomViewList{
                situationRoomid
                lastViewed
                project_id
            }
            jobDescription
            preferences {
                cookies
                marketingEmails
                productEmails
                darkmode
            }
            welcomeMessage
        }
    }
`;

export const UPDATE_PROFILE = gql`
    mutation updateProfile($data: Auth0ProfileUpdate){
        updateProfile(data: $data){
            message
            done
            photoURL
        }
    }
`;

export const SUBMIT_CLAIM_TO_FC = gql`
    mutation SubmitClaim($data: SubmitClaimToFCdata){
        submitClaimToFc(data: $data) {
            claimId
        }
    }
`;

export const CREATE_SITUATION_ROOM = gql`
           mutation createSituationRoom(
                $instance: ID!
                $project_name: String!
                $project_id: String!
                $start_date: String
                $end_date: String
                $createdBy: ID
                $query: QueryInput
                $query_type: String
                $actuallyCreatedBy: ID
                $limit: String
                $reason: String
                $narrative_boolean: Boolean
                $contentVisibilityRestriction: String
           ) {
               createSituationRoom(
                   data: {
                        instance: $instance
                        project_name: $project_name
                        project_id: $project_id
                        createdBy: $createdBy
                        actuallyCreatedBy: $actuallyCreatedBy
                        query: $query
                        query_type: $query_type
                        start_date: $start_date
                        end_date: $end_date
                        limit: $limit
                        reason: $reason
                        narrative_boolean: $narrative_boolean
                        contentVisibilityRestriction: $contentVisibilityRestriction
                   }
               ) {
                   id
               }
           }
       `;

export const CREATE_SITUATION_ROOM_DRAFT = gql`
           mutation createSituationRoomDraft(
                $id: ID
                $instance: ID!
                $project_name: String!
                $project_id: String!
                $start_date: String
                $end_date: String
                $createdBy: ID
                $query: QueryInput
                $query_type: String
                $actuallyCreatedBy: ID
                $limit: String
                $reason: String
                $contentVisibilityRestriction: String
           ) {
               createSituationRoomDraft(
                   data: {
                        situationRoomId: $id
                        instance: $instance
                        project_name: $project_name
                        project_id: $project_id
                        createdBy: $createdBy
                        actuallyCreatedBy: $actuallyCreatedBy
                        query: $query
                        query_type: $query_type
                        start_date: $start_date
                        end_date: $end_date
                        limit: $limit
                        reason: $reason
                       contentVisibilityRestriction: $contentVisibilityRestriction
                   }
               ) {
                   id
               }
           }
       `;


export const DELETE_SITUATION_ROOM_DRAFT = gql`
    mutation deleteSituationRoomDraft($id: ID!, $instance: ID!) {
        deleteSituationRoomDraft(data: {
            id: $id,
            instance: $instance
        }) {
            id
        }
    }
`;

export const DELETE_USER = gql`
    mutation deleteUser($id: ID!, $uid: String!, $instance: ID) {
        deleteUser(data: {
            id: $id,
            uid: $uid,
            instance: $instance
        }) {
            id
        }
    }
`;

export const CREATE_ADMIN_USER = gql`
    mutation createAdminUser($uid: String, $email: String!, $displayName: String, $photoURL: String, $role: String) {
        createAdminUser(data: {
             uid: $uid,
             email: $email,
             displayName: $displayName,
             photoURL: $photoURL,
             role: $role
            }) {
            id
            uid
            email
            displayName
            photoURL
            role
        }
    }
`;

export const UPDATE_ADMIN_USER = gql`
    mutation updateAdminUser($data: UserInput) {
        updateAdminUser(data: $data) {
            id
            uid
            email
            displayName
            photoURL
            role
        }
    }
`;

export const DELETE_ADMIN_USER = gql`
    mutation deleteAdminUser($id: ID!, $uid: String) {
        deleteAdminUser(data: {
            id: $id,
            uid: $uid
        }) {
            done
        }
    }
`;

export const CREATE_INSTANCE = gql`
    mutation createInstance($data: InstanceCreateInput) {
        createInstance(data: $data) {
                id
                clientName
                account {
                    contactName
                    role
                    email
                    phoneNumber
                    location
                    accountManager
                    notes
                    contractLink
                }
                tier
                plan {
                    isTrial
                    restrictedTeamRole
                    validFrom
                    validUntil
                    tokens
                    userLimit
                    situationroomLimit
                    disabledThreatTypes
                    core {
                        overview
                        threats
                        logicallyFactChecks
                        narratives
                        aiClustering
                        networks
                    }
                    integrations {
                        investigations
                    }
                }
        }
    }
`;

export const UPDATE_INSTANCE = gql`
    mutation updateInstance($data: InstanceUpdateInput) {
        updateInstance(data: $data) {
                id
                clientName
                account {
                    contactName
                    role
                    email
                    phoneNumber
                    location
                    accountManager
                    notes
                    contractLink
                }
                tier
                plan {
                    isTrial
                    restrictedTeamRole
                    validFrom
                    validUntil
                    tokens
                    userLimit
                    tokensRequestedBy {
                        email
                        displayName
                    }
                    situationroomLimit
                    disabledThreatTypes
                    core {
                        overview
                        threats
                        logicallyFactChecks
                        narratives
                        aiClustering
                        networks
                    }
                    integrations {
                        investigations
                    }
                }
        }
    }
`;

export const DELETE_INSTANCE = gql`
    mutation deleteInstance($id: ID!) {
        deleteInstance(data: {
            id: $id,
        }) {
            id
        }
    }
`;

export const UPDATE_SITUATION_ROOM_LAST_VIEWED = gql`
    mutation updateSituationRoomLastViewed($situationRoomId:String!,$lastViewed:String!,$uid:String!){
        updateSituationRoomLastViewed(data:{situationRoomId:$situationRoomId,lastViewed:$lastViewed,uid:$uid}){
            id
        }
    }

`;

export const UPDATE_SITUATION_ROOM = gql`
    mutation updateSituationRoom(
        $id: ID!,
        $project_id: String,
        $draftId: ID,
        $private: Boolean,
        $users: [ID],
        $project_name: String,
        $archived: Boolean,
        $archivedDetails: ArchivedDetailsInput,
        $is_active: Boolean,
        $query: QueryInput,
        $query_type: String,
        $action: String,
        $deleted: Boolean,
        $paused: Boolean,
        $createdBy: ID,
        $actuallyCreatedBy: ID,
        $active: Boolean,
        $limit: String,
        $updatedBy: ID,
        $removedUsers: [String],
        $start_date: String,
        $booleanTitle: String,
        $notes: String
        $reason: String
        $narrative_boolean: Boolean
        $instance: ID
        $contentVisibilityRestriction: String
    ) {
        updateSituationRoom(
            data: {
                id: $id,
                project_id: $project_id,
                draftId: $draftId,
                private: $private,
                users: $users,
                project_name: $project_name,
                archived: $archived,
                archivedDetails: $archivedDetails,
                is_active: $is_active,
                query: $query,
                query_type: $query_type,
                action: $action,
                deleted: $deleted,
                paused: $paused,
                createdBy: $createdBy,
                actuallyCreatedBy: $actuallyCreatedBy,
                active: $active
                limit: $limit
                updatedBy: $updatedBy
                removedUsers: $removedUsers
                start_date: $start_date
                reason: $reason
                narrative_boolean: $narrative_boolean
                instance: $instance
                contentVisibilityRestriction: $contentVisibilityRestriction
            },
            booleanVersionMetadata: {
                booleanTitle: $booleanTitle
                notes: $notes
            }
        ) {
            id
        }
    }
`;

export const UPDATE_SITUATION_ROOM_DRAFT = gql`
    mutation updateSituationRoomDraft(
        $id: ID!,
        $project_id: String,
        $situationRoomId: ID,
        $private: Boolean,
        $users: [ID],
        $project_name: String,
        $archived: Boolean,
        $is_active: Boolean,
        $query: QueryInput,
        $query_type: String,
        $action: String,
        $deleted: Boolean,
        $paused: Boolean,
        $createdBy: ID,
        $actuallyCreatedBy: ID,
        $active: Boolean,
        $limit: String,
        $start_date: String,
        $reason: String
        $contentVisibilityRestriction: String
    ) {
        updateSituationRoomDraft(data: {
            id: $id,
            project_id: $project_id,
            private: $private,
            situationRoomId: $situationRoomId,
            users: $users,
            project_name: $project_name,
            archived: $archived,
            is_active: $is_active,
            query: $query,
            query_type: $query_type,
            action: $action,
            deleted: $deleted,
            paused: $paused,
            createdBy: $createdBy,
            actuallyCreatedBy: $actuallyCreatedBy,
            active: $active
            limit: $limit
            start_date: $start_date
            reason: $reason
            contentVisibilityRestriction: $contentVisibilityRestriction
        }) {
            id
        }
    }
`;

export const ADD_TO_WATCHLIST = gql`
    mutation addToWatchlist($situationRoom: ID!, $watchlistItem: [ThreatInput]) {
        addToWatchlist(data: {
            situationRoom: $situationRoom,
            watchlistItem: $watchlistItem,
        })
    }
`;

export const REMOVE_FROM_WATCHLIST = gql`
    mutation removeFromWatchlist($situationRoom: ID!, $contentId: [String]) {
        removeFromWatchlist(data: {
            situationRoom: $situationRoom,
            contentId: $contentId
        })
    }
`;

export const CREATE_INVESTIGATION = gql`
    mutation createInvestigation($data: InvestigationCreateInput) {
        createInvestigation(data: $data) {
            id
            summary
            createdAt
            updatedAt
            status
            details
            uploadAttachments
        }
    }
`;

export const UPDATE_INVESTIGATION = gql`
    mutation updateInvestigation($data: InvestigationUpdateInput) {
        updateInvestigation(data: $data) {
            id
            summary
            createdAt
            updatedAt
            note
            status
            details
            report {
                id
            }
            contactEmails
        }
    }
`;

export const UPLOAD_INVESTIGATION_REPORT = gql`
    mutation uploadInvestigationReport($file: Upload!) {
        uploadInvestigationReport(file: $file) {
            id
        }
    }
`;

export const DELETE_INVESTIGATION = gql`
    mutation deleteInvestigation($id: ID!) {
        deleteInvestigation(id: $id) {
            id
        }
    }
`;

export const CREATE_CUSTOM_NARRATIVE = gql`
    mutation createCustomNarrative($data: CustomNarrativeCreateInput) {
        createCustomNarrative(data: $data) {
            id
            name
            data {
                id
                threat
                boolean
                keywords
                query_type
                dslQuery
            }
        }
    }
`;

export const UPDATE_CUSTOM_NARRATIVE = gql`
    mutation updateCustomNarrative($data: CustomNarrativeUpdateInput) {
        updateCustomNarrative(data: $data) {
            id
            name
            narrativeTitle
            versionId
            notes
            data {
                id
                threat
                boolean
                keywords
                dslQuery
            }
        }
    }
`;

export const DELETE_CUSTOM_NARRATIVE = gql`
    mutation deleteCustomNarrative($id: ID!) {
        deleteCustomNarrative(data: {
            id: $id,
        }) {
            id
        }
    }
`;

export const INVITE_USER = gql`
    mutation inviteUser($data: InviteInput) {
        inviteUser(data: $data) {
            done
            message
        }
    }
`;

export const FORGOT_PASSWORD = gql`
    mutation forgotPassword($email: String!){
        forgotPassword(email: $email)
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($code: String!, $email: String!, $password: String!){
        resetPassword(code: $code, email: $email, password: $password){
            done
            message
        }
    }
`;

// This is the new nodejs endpoint ONLY to remove the content
export const REMOVE_CONTENT = gql`
    mutation removeContent($type: String, $situationRoomId: String, $projectId: String, $data: [RemoveContentBodyInput]){
        removeContent(type: $type, situationRoomId: $situationRoomId, projectId: $projectId, data: $data)
    }
`;

export const REMOVE_CONTENT_PERMANENTLY = gql`
    mutation removeContentPermanently($projectId: String, $documentIds: [String]){
        removeContentPermanently(projectId: $projectId, documentIds: $documentIds)
    }
`;

// This is the old django endpoint used for anything but not removal of content
export const REMOVE_CONTENT_DJANGO = gql`
    mutation removeContentDjango($type: String, $situationRoomId: String, $projectId: String, $data: [RemoveContentBodyInput]){
        removeContentDjango(type: $type, situationRoomId: $situationRoomId, projectId: $projectId, data: $data)
    }
`;

export const RECOVER_CONTENT = gql`
    mutation recoverContent($projectId: String, $docIds: [String]){
        recoverContent(projectId: $projectId, ids: $docIds)
    }
`;

export const DELETE_CTYPE = gql`
mutation deleteCtype($id: ID!){
    deleteCtype(id: $id){
        id
    }
}
`;

export const UPDATE_CTYPE = gql`
mutation updateCtype($data: UpdateCtypeInput!){
    updateCtype(data: $data){
        id
        key
        name
        color
        FontFamily
        nodeColor
        icon
    }
}
`;

export const ADD_CTYPE = gql`
    mutation createCtype($data: CreateCtypeInput){
        createCtype(data: $data){
            id
            key
            name
            color
            FontFamily
            nodeColor
            icon
        }
    }
`;

export const REQUEST_TOKENS = gql`
    mutation requestTokens($data: TokenRequestedInput!){
        requestTokens(data: $data){
            done
            message
        }
    }
`;

export const UPDATE_TOKENS = gql`
    mutation updateTokens($data: InvestigationTokensInput!){
        updateTokens(data: $data){
            done
            message
        }
    }
`;

export const REMOVE_CLUSTER_SUBCLUSTER = gql`
    mutation removeClustersAndSubclusters($projectId: String!, $clusterId: String, $subClusterId: String){
        removeClustersAndSubclusters(projectId: $projectId, clusterId: $clusterId, subClusterId: $subClusterId)
    }
`;

export const REMOVE_CLUSTERS = gql`
    mutation removeClusters($projectId: String!, $clusterIds: [String]){
        removeClusters(projectId: $projectId, clusterIds: $clusterIds)
    }
`;

export const REMOVE_SUB_CLUSTERS = gql`
    mutation removeSubClusters($projectId: String!, $subClusterIds: [String]){
        removeSubClusters(projectId: $projectId, subClusterIds: $subClusterIds)
    }
`;

export const EDIT_CLUSTER_SUBCLUSTER = gql`
    mutation editTitleAndSummary($projectId: String!, $clusterId: String, $subClusterId: String, $data: ClusterTypeInput){
        editTitleAndSummary(projectId: $projectId, clusterId: $clusterId, subClusterId: $subClusterId, data: $data)
    }
`;

export const CHANGE_CLUSTER_OR_SUBCLUSTER_STATUS = gql`
    mutation changeStatus($projectId: String!, $clusterIds: [String], $subClusterIds: [String], $removedBy: String, $status: String){
        changeStatus(projectId: $projectId, clusterIds: $clusterIds, subClusterIds: $subClusterIds, removedBy: $removedBy, status: $status)
    }
`;

/* eslint-enable */
