import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import Highcharts from 'highcharts';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import HCExporting from 'highcharts/modules/exporting';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { convertESInterval } from '../../../../../utils/convertESInterval';
import { getEndDate } from '../../../../../utils/getIntervalDateRange';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { color } from '../../../../../utils/getColors';
import { getRoom } from '../../../../../utils/variables';
import { saveFilters } from '../../../store';
import { ResponsiveChart, ResponsiveChartRef } from '../../../../../components/Highcharts/ResponsiveChart';
import { ExportChartProps, useChartExport } from '../../../../../components/Highcharts/useChartExport';

HCExporting(Highcharts);

type LineChartSeries = {
    name: string
    data: any
    color: string
    visible: boolean
}

type LineChartProps = {
    preview?: boolean
    data: LineChartSeries[]
    interval?: string
}

export type LineChartRef = {
    exportChart: (data: ExportChartProps) => void
}

export const LineChart = forwardRef(({ preview, data, interval: rawInterval }: LineChartProps, ref) => {
    const { setModal } = useModal();
    const dispatch = useDispatch();
    const room = getRoom();
    const history = useHistory();

    const getContentFilter = ({ seriesName, date, end }: { seriesName: string; date: number; end: number }) => ({
        date: {
            startDate: Math.floor(date / 1000),
            endDate: Math.floor(end / 1000)
        },
        ...(seriesName === 'Threats' ? { isThreat: true } : {})
    });

    const chartRef = useRef<ResponsiveChartRef>(null);

    const exportChart = useChartExport({
        chartRef,
        fileName: 'total-matches-and-threats-over-time'
    });

    useImperativeHandle(ref, () => ({
        exportChart
    }));

    const interval = convertESInterval(rawInterval || '1d');
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    const handleOnClick = (point: Highcharts.Point) => {
        const seriesName = point.series.name;
        const buckets = data.find(series => series.name === seriesName)?.data || [];
        if (buckets.length === 0) return;
        const date = point.category;
        const endDate = typeof (interval) === 'string' ? getEndDate(date, interval) : date + interval;
        const end = buckets[point.index + 1] ? buckets[point.index + 1].x - 1
            : endDate;

        const filters = getContentFilter({ seriesName, date: parseInt(date.toString(), 10), end: parseInt(end.toString(), 10) });

        if (betaFiltersEnabled) {
            dispatch(saveFilters({
                date: { startDate: filters.date.startDate, endDate: filters.date.endDate },
                dateRange: { startDate: filters.date.startDate, endDate: filters.date.endDate },
                roomId: room?.id
            }));
            return history.push({
                pathname: `/situation-rooms/${room.id}/overview/matches`,
                state: {
                    isThreat: filters?.isThreat || false,
                    appliedFilter: {
                        date: []
                    }
                }
            });
        }

        return setModal({
            header: `${point.series.name} - ${moment(new Date(date)).format('DD/MM/YYYY')}`,
            size: 'xl',
            goToPreviousOnClose: true,
            component: preview ? <ContentPreviewModal filter={{ filter: filters }} contentType={CONTENT_TYPE.THREAT} preview={preview} />
                : <ContentModal filter={{ filter: filters }} contentType={CONTENT_TYPE.THREAT} />
        });
    };
    const resetZoomButtonRef = useRef<Highcharts.SVGElement | null>(null);
    const options: Highcharts.Options = {
        title: {
            text: ''
        },
        chart: {
            height: 450,
            spacing: [10, 10, 3, 0],
            backgroundColor: 'transparent',
            zoomType: 'x',
            plotBorderWidth: 1,
            plotBorderColor: color.grey[300],
            events: {
                selection() {
                    const chart = this as Highcharts.Chart;
                    const { renderer, xAxis } = chart;

                    if (resetZoomButtonRef.current) {
                        resetZoomButtonRef.current.destroy();
                    }

                    resetZoomButtonRef.current = renderer.button('Reset zoom', 0, 0, () => {
                        xAxis[0].setExtremes();
                        if (resetZoomButtonRef.current) {
                            resetZoomButtonRef.current.destroy();
                            resetZoomButtonRef.current = null;
                        }
                    }, {
                        zIndex: 7,
                        fill: '#fff',
                        stroke: '#006FF9',
                        style: {
                            color: '#006FF9'
                        }
                    }, {
                        fill: '#006FF9',
                        style: {
                            color: '#fff'
                        }
                    }).attr({
                        id: 'resetZoom',
                        align: 'right',
                        title: 'Reset zoom level'
                    }).add().align({
                        align: 'right',
                        x: -20,
                        y: 20
                    }, false);
                    return undefined;
                }
            }
        },
        series: data.map((item) => ({
            name: item.name,
            data: item.data,
            type: 'spline',
            color: item.color,
            marker: {
                enabled: false,
                fillColor: '#fff',
                lineColor: item.color,
                lineWidth: 2,
                radius: 2,
                symbol: 'circle'
            },
            states: {
                hover: {
                    halo: {
                        size: 5
                    }
                }
            },
            visible: item.visible
        })),
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: {
                    main: '%d/%m/%Y'
                },
                month: {
                    main: '%m/%Y'
                },
                week: {
                    main: '%d/%m/%Y'
                },
            },
            gridLineColor: color.grey[300],
            gridLineWidth: 1,
            lineWidth: 1,
            lineColor: color.grey[300],
            labels: {
                style: {
                    color: color.grey[400]
                }
            }
        },
        yAxis: {
            gridLineColor: color.grey[300],
            gridLineWidth: 1,
            opposite: false,
            lineWidth: 1,
            lineColor: color.grey[300],
            labels: {
                style: {
                    color: color.grey[400]
                }
            },
            title: {
                enabled: false
            } as any
        },
        tooltip: {
            shared: true,
            split: false,
            backgroundColor: '#fff',
            borderWidth: 0,
            padding: 0,
            headerFormat: `<div class="d-flex justify-content-between p-1">
                <span class="mr-4">Date</span>
                <span>{point.key}</span>
            </div><hr style="margin: 2px 0;" />`,
            pointFormatter() {
                const { series, y } = this;
                return `<div class="d-flex justify-content-between p-1">
                    <div class="d-flex align-items-center">
                        <div class="mr-1" style="background-color: ${(series as any).color};
                        height: 12px; width: 12px; border-radius: 2px;"></div>
                        ${series.name}
                    </div>
                    <span>${y}</span>
                </div><hr class="m-0" />`;
            },
            xDateFormat: '%d/%m/%Y %l:%M %p',
            style: {
                color: color.darkblue[700],
                fontFamily: 'inherit',
                fontSize: '14px'
            },
            useHTML: true
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        click: ({ point }) => {
                            handleOnClick(point);
                        }
                    }
                }
            }
        },
        navigator: {
            enabled: false
        },
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        legend: {
            enabled: false
        }
    };
    return (
        <div className="highcharts-stock-chart">
            <ResponsiveChart options={options} ref={chartRef} />
        </div>
    );
});
