/* eslint-disable no-console */
import React, { useRef } from 'react';
import moment from 'moment';
import './chart.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { LoadingState } from '../Table/Loading';
import { config } from '../utils';
import { ResponsiveChart } from '../../../../../components/Highcharts';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { getEndDate } from '../../../../../utils/getIntervalDateRange';
import { dateTimeLabelFormats } from '../../../../../components/Highcharts/config';
import { EmptyState } from '../../../../../components/EmptyState';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { Images } from '../../../../../utils/modeSwitch';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { useTopEntityMentions } from './useTopEntityMentions';
import { color } from '../../../../../utils/getColors';
import { checkAndGetUnix } from '../../../../../utils/getTimeFrom';
import { saveFilters } from '../../../store';
import { getRoom } from '../../../../../utils/variables';
import { useChartExport } from '../../../../../components/Highcharts/useChartExport';
import { ActionsDropdown } from '../../../../../components/Atoms/ActionsDropdown';

export const TopEntityMentions = ({ preview }) => {
    const { setModal } = useModal();
    const dispatch = useDispatch();
    const room = getRoom();
    const history = useHistory();
    const { entities, loading, lessThanWeek, interval } = useTopEntityMentions({ preview });
    const chartRef = useRef(null);

    const exportChart = useChartExport({
        chartRef,
        fileName: 'top-entity-mentions'
    });

    if (loading) { return <LoadingState size={5} />; }
    if (!entities.length) {
        return (
            <div className="border-top mx-n3 pt-4">
                <EmptyState title="No data available" icon={Images().overview} />
            </div>
        );
    }
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    const formattedData = formatData({ entities, interval });
    const getContentModal = ({ chartPoint, name, dateFilter }) => {
        const ContentModalComponent = preview ? ContentPreviewModal : ContentModal;
        return (
            <ContentModalComponent name={chartPoint.series.name}
                filter={{ value: [name], type: 'entities', filter: dateFilter }}
                contentType={CONTENT_TYPE.THREAT}
                preview={preview || false}
            />
        );
    };
    const handleOnClick = (point) => {
        const series = formattedData.find(item => item.name === point.series.name);
        const name = series?.original || point.series.name;
        const date = new Date(point.category);
        const endDate = getEndDate(date, interval || '1d');
        const dateFilter = {
            date: {
                endDate: moment.utc(endDate).endOf(lessThanWeek ? 'hour' : 'day').valueOf(),
                startDate: moment.utc(date).startOf(lessThanWeek ? 'hour' : 'day').valueOf()
            }
        };
        const dateFormat = lessThanWeek ? 'DD/MM/YYYY, hh:mm a' : 'DD/MM/YYYY';
        if (betaFiltersEnabled) {
            dispatch(saveFilters({
                date: { startDate: checkAndGetUnix(dateFilter.date.startDate), endDate: checkAndGetUnix(dateFilter.date.endDate) },
                dateRange: { startDate: checkAndGetUnix(dateFilter.date.startDate), endDate: checkAndGetUnix(dateFilter.date.endDate) },
                roomId: room?.id
            }));
            return history.push({ pathname: `/situation-rooms/${room.id}/overview/matches`,
                state: { value: name,
                    type: 'entities',
                    appliedFilter: {
                        date: []
                    } } });
        }

        return setModal({
            header: `${point.series.name} on ${moment(date).format(dateFormat)} - ${moment(endDate).format(dateFormat)}`,
            size: 'xl',
            component: getContentModal({ chartPoint: point, name, dateFilter })
        });
    };
    const options = {
        title: {
            text: ''
        },
        chart: {
            type: 'bar'
        },
        colors: config.colors,
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats,
            labels: {
                style: {
                    color: color.grey[400]
                }
            }
        },
        tooltip: {
            useHTML: true,
            headerFormat: '',
            backgroundColor: 'rgba(246, 246, 246, 1)',
            style: {
                opacity: 1,
                background: 'rgba(246, 246, 246, 1)'
            },
            pointFormatter() {
                const { series, category, y } = this;
                return (
                    `<div style="opacity:1; width: auto;">
                    <div style="display:flex; align-items:center;">
                    Keyword&nbsp;
                    <div style="margin-left: 8px; height: 10px; width: 10px; border-radius: 2; background-color: ${series.color}">
                    </div>
                    <span style="padding-left:5px;">${series.name}</span></div><hr style="margin:0"/>
                    <div>Date&nbsp;<span style="float:right">${moment(new Date(category)).format('DD/MM/YYYY')}</span>
                    </div><hr style="margin:0"/>
                    <div>Mentions&nbsp;<span style="float:right">${y}</span></div>
                    </div>`); }
        },
        yAxis: {
            min: 0,
            title: '',
            labels: {
                style: {
                    color: color.grey[400]
                }
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal',
                events: {
                    click: ({ point }) => {
                        handleOnClick(point);
                    }
                }
            }
        },
        legend: {
            reversed: true,
            symbolRadius: 0
        },
        series: formattedData
    };
    return (
        <div className="ml-n1 mt-1 mb-n1 w-100" data-testid="topEntitiesMentions">
            <div className="top-right position-absolute mr-3 mt-3">
                <ActionsDropdown className="ml-auto">
                    <DropdownItem onClick={() => exportChart()}>
                        Export as JPEG
                    </DropdownItem>
                </ActionsDropdown>
            </div>
            <ResponsiveChart options={options} ref={chartRef} />
        </div>
    );
};

const formatData = ({ entities, interval }) => entities.map((dat, i) => {
    const trend = [...dat.trend].sort((a, b) => a.date - b.date);
    return {
        name: dat.entity,
        data: trend.map((a) => ({
            x: a.date,
            y: a.count
        })),
        interval,
        marker: {
            enabled: false
        },
        dataLabels: trend.map(() => ({
            enabled: false,
            backgroundColor: config.colors[i],
            color: '#fff',
            borderRadius: 0,
            padding: 3,
            verticalAlign: 'left',
            style: {
                textOutline: 'none',
            }
        }))
    };
});
