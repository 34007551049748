import React, { PropsWithChildren } from 'react';
import { Row, Col, Collapse } from 'reactstrap';
import { ChevronDown, ChevronUp } from 'react-feather';
import { color } from '../../../utils/getColors';
import { AccordionItemProps } from './AccordionItem';

 type AccordionViewProps = AccordionItemProps & {
    index: string
    activeIndex: string
    toggle: (id: string) => any
}

export const AccordionView = ({
    header,
    index,
    activeIndex,
    toggle,
    iconOpen,
    iconClose,
    useSeparator = true,
    children
}: PropsWithChildren<AccordionViewProps>) => (
    <Col className="p-0" xs={12}>
        <Col className="p-0">
            <Row className="justify-content-between p-0 m-0">
                <Col xs="auto" className="p-0">{header}</Col>
                <Col xs="auto" className="p-0" onClick={() => toggle(index)} data-testid="accordion-icon">
                    <span>
                        {(activeIndex === index)
                            ? iconClose || <ChevronUp color={color.blue[500]} size={24} data-testid="accordion-icon-close" />
                            : iconOpen || <ChevronDown color={color.blue[500]} size={24} data-testid="accordion-icon-open" />}
                    </span>
                </Col>
            </Row>
        </Col>
        <Col className="p-0" style={activeIndex !== index ? { visibility: 'hidden' } : {}}>
            <Collapse isOpen={activeIndex === index}>{children}</Collapse>
        </Col>
        {(useSeparator) && <hr className="mt-2 mb-3" data-testid="accordion-separator" />}
    </Col>
);
