import React, { createRef, forwardRef, useImperativeHandle } from 'react';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { saveSvgAsPng } from 'save-svg-as-png';
import { entityTypes } from '../utils';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { useWordcloud } from '../../../../../services/Overview';
import { color } from '../../../../../utils/getColors';

export const WordCloud = forwardRef(({ field, onWordClick, size }, ref) => {
    const { keywords, loading, error } = useWordcloud({ field, size });
    const wordcloudRef = createRef();
    useImperativeHandle(ref, () => ({
        exportWordcloud: async () => {
            if (loading || !keywords.length) return;
            const svgElement = wordcloudRef.current.querySelector('svg');
            saveSvgAsPng(svgElement, 'wordcloud.png');
        }
    }));
    if (loading) {
        return (
            <div className="loading-container pt-3">
                <div className="loading-square-keyword-mentions loading-bg w-100">
                    <p>Loading</p>
                </div>
            </div>
        );
    }
    if (!keywords.length) {
        return (
            <EmptyState title="No data available" icon={Images().overview} />
        );
    }
    if (error) return `Error! ${error}`;
    const options = {
        colors: [color.blue[500]],
        enableTooltip: false,
        fontFamily: 'nunito',
        fontSizes: field === 'text' ? [15, 20] : [25, 50],
        fontStyle: 'normal',
        fontWeight: '800',
        rotations: 0,
        padding: 0,
        rotationAngles: [0, 90],
        scale: 'linear',
        spiral: 'rectangular',
        transitionDuration: 1000,
        deterministic: true,
    };
    const callbacks = {
        getWordColor: (word) => {
            if (field === 'entities') {
                return entityTypes.find(type => type.key === word.type)?.color || color.blue[500];
            }
            return color.blue[500];
        },
        onWordClick: onWordClick || defaultFunction
    };
    const defaultFunction = () => {};
    if (!keywords.length) { return null; }
    return (
        <div style={{ height: `calc(100% - ${field === 'entities' ? 140 : 100}px)` }}>
            <span ref={wordcloudRef}>
                <ReactWordcloud callbacks={callbacks}
                    options={options}
                    words={keywords.map(item => ({
                        text: trimString(item.keyword, 40),
                        value: item.count,
                        original: item.key,
                        ...item
                    }))}
                />
            </span>
            {field === 'entities' && (
                <div className="d-flex align-items-center flex-wrap mt-2">
                    {entityTypes.map(type => (
                        <div key={type.key} className="d-flex align-items-center mr-2">
                            <div className="p-1 rounded-sm mr-11" style={{ backgroundColor: type.color }} />
                            <span className="font-weight-semibold">{type.name}</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
});

const trimString = (string, length) => (string.length > length ? `${string.substring(0, length)}...` : string);
