import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useRemoveRestoreCluster } from '../../../../../services/Clusters/removeRestore';

type ClusterIdAndStatusType = {
    id: string
    status?: string
    subClustersMappedId?: string[]
}

type RemoveClustersConfirmationProps = {
    clusterIdAndStatus: ClusterIdAndStatusType[]
    onCompleted: () => void
}

export const RemoveClustersConfirmation = ({ clusterIdAndStatus, onCompleted }: RemoveClustersConfirmationProps) => {
    const { closeModal } = useModal();
    const history = useHistory();
    const clusterIds = clusterIdAndStatus?.map(({ id }) => id);
    const subClustersMappedIds = clusterIdAndStatus?.map(({ subClustersMappedId }) => subClustersMappedId || []).flat();
    const { handlePermanantRemoveClusters, handleSendtoRemoveContent, loading } = useRemoveRestoreCluster();
    const checkDeleteStatus = () => {
        // This ensures we are removing it permanently
        if (clusterIdAndStatus?.[0]?.status === 'deleted' && history.location?.pathname?.includes('settings')) {
            handlePermanantRemoveClusters(clusterIds, subClustersMappedIds);
        } else {
            handleSendtoRemoveContent(clusterIds, 'cluster', subClustersMappedIds);
        }
    };
    return (
        <>
            <h3>Are you sure that you want to remove these narrative themes?</h3>
            <hr />
            <p>The selected narrative themes will be fully removed from this research environment.</p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={loading}
                color="danger"
                onClick={checkDeleteStatus}
            >Remove
            </Button>
        </>
    );
};
