import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DropdownItem } from 'reactstrap';
import { PieChart } from '../../../../../components/Highcharts';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { config, TitleTooltip } from '../utils';
import { LoadingState } from './Loading';
import { ContentPreviewModal } from '../../ContentModal/contentPreviewModal';
import { ContentModal } from '../../ContentModal/contentModal';
import { useSentiment } from '../../../../../services/Overview';
import { CONTENT_TYPE } from '../../../../../utils/contentTypes';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { getRoom } from '../../../../../utils/variables';
import { saveFilters } from '../../../store';
import { ActionsDropdown } from '../../../../../components/Atoms/ActionsDropdown';

export const CurrentSentiment = ({ preview }) => {
    const { setModal } = useModal();
    const room = getRoom();
    const dispatch = useDispatch();
    const history = useHistory();
    const { data, loading } = useSentiment();
    const betaFiltersEnabled = !!room.instance?.plan?.others?.betaFilters;
    const chartRef = useRef(null);

    if (loading) {
        return <LoadingState />;
    }

    const total = data.reduce((acc, { count }) => acc + count, 0);

    const getContentModal = ({ sentiment }) => {
        const filter = { value: [sentiment.toLowerCase()], type: 'sentiment__keyword' };

        if (preview) {
            return <ContentPreviewModal name={sentiment} filter={filter} contentType={CONTENT_TYPE.THREAT} preview={preview} />;
        }

        return <ContentModal name={sentiment} filter={filter} contentType={CONTENT_TYPE.THREAT} />;
    };

    const handleOnClick = (sentiment) => {
        if (betaFiltersEnabled) {
            dispatch(saveFilters({
                sentiment: [sentiment.toLowerCase()],
                roomId: room?.id
            }));
            return history.push({ pathname: `/situation-rooms/${room.id}/overview/matches`,
                state: { appliedFilter: { sentiment: [sentiment.toLowerCase()] } } });
        }
        setModal({
            header: sentiment,
            size: 'xl',
            component: getContentModal({ sentiment })
        });
    };

    const chartExportOptions = {
        legend: {
            enabled: true
        },
        chart: {
            height: 500
        }
    };

    return (
        <div className="h-100 d-flex flex-column">
            <div className="d-flex">
                <TitleTooltip title="Current Sentiment" type="sentiment__keyword" />
                {data.length ? (
                    <ActionsDropdown className="ml-auto">
                        <DropdownItem onClick={() => chartRef.current?.exportChart({ chartOptions: chartExportOptions })}>
                            Export as JPEG
                        </DropdownItem>
                    </ActionsDropdown>
                ) : null}
            </div>
            {!data.length ? <EmptyState title="No data available" icon={Images().overview} /> : (
                <>
                    <PieChart data={data.map(({ count }) => count)}
                        labels={data.map(item => item.sentiment)}
                        colors={data.map((item, i) => config.getColor(item.sentiment.toLowerCase(), i))}
                        legendEnabled={false}
                        height="213px"
                        innerSize="80%"
                        ref={chartRef}
                    />
                    <div>
                        {data.map(({ sentiment, count }, i) => {
                            const percentage = (100 * count) / total;
                            return (
                                <div className="d-flex align-items-center" key={sentiment}>
                                    <div style={{
                                        height: 11,
                                        width: 11,
                                        borderRadius: 2,
                                        backgroundColor: config.getColor(sentiment.toLowerCase(), i),
                                    }}
                                    />
                                    <p className="mb-0 pl-1 text-primary"
                                        role="button"
                                        onClick={() => handleOnClick(sentiment)}
                                    >
                                        {sentiment} ({Math.round(percentage)}%)
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};
