import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { useRemoveRestoreCluster } from '../../../../../services/Clusters/removeRestore';

type SubclusterIdAndStatusType = {
    id: string
    status?: string
}

type RemoveSubClustersConfirmationProps = {
    subClusterIdAndStatus: SubclusterIdAndStatusType[]
    onCompleted: () => void
}

export const RemoveSubClustersConfirmation = ({ subClusterIdAndStatus, onCompleted }: RemoveSubClustersConfirmationProps) => {
    const { closeModal } = useModal();
    const history = useHistory();
    const subClusterIds = subClusterIdAndStatus?.map(({ id }) => id);
    const { handlePermanantRemoveSubClusters, handleSendtoRemoveContent, loading } = useRemoveRestoreCluster();
    const checkDeleteStatus = () => {
        // This ensures we are removing it permanently
        if (subClusterIdAndStatus?.[0]?.status === 'deleted' && history.location?.pathname?.includes('settings')) {
            handlePermanantRemoveSubClusters(subClusterIds);
        } else {
            handleSendtoRemoveContent(subClusterIds, 'subcluster');
        }
    };
    return (
        <>
            <h3>Are you sure that you want to remove these narratives?</h3>
            <hr />
            <p>The selected narratives will be fully removed from this research environment.</p>
            <hr />
            <Button color="secondary" className="mr-2" onClick={closeModal}>Cancel</Button>
            <Button disabled={loading} color="danger" onClick={checkDeleteStatus}>Remove</Button>
        </>
    );
};
